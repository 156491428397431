import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, ComparisonTable, KMCurve, EndpointDisplay, NCCNcallout, ReferencesBlock, PatientProfile, FeaturedStat } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import NSCLC_1L_COMBO_SQ from '../../components/StudyDesign/__study-design/nsclc-1l-combo-sq'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-407 - Clinical Trial Results | HCP`,
    keywords: `keynote-407`,
    description: `Health care professionals may find clinical trial results for first-line combination therapy with KEYTRUDA® (pembrolizumab) for squamous mNSCLC in KEYNOTE-407.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-squamous-first-line-combination-therapy/","@type":"ApprovedIndication","name":" KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with carboplatin and either paclitaxel or paclitaxel protein‑bound, is indicated for the first‑line treatment of patients with metastatic squamous non⁠–⁠small cell lung cancer (NSCLC). "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-squamous-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-squamous-first-line-combination-therapy/","@type":"MedicalTrial","name":"Clinical Findings From KEYNOTE⁠-⁠407","description":"Study Design for KEYNOTE⁠-⁠407","trialDesign":"Phase 3, randomized, multicenter, double-blind, placebo-controlled trial. Enrolled systemic therapy–naïve patients with metastatic squamous NSCLC, regardless of PD⁠-⁠L1 tumor expression status. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-squamous-first-line-combination-therapy/","@type":"MedicalGuideline","guideline":"Pembrolizumab (KEYTRUDA), in combination with carboplatin and paclitaxel or paclitaxel protein⁠-⁠bound, is a recommended (category 1 and preferred) first-line treatment for patients with metastatic squamous non⁠–⁠small cell lung cancer (NSCLC), regardless of PD⁠-⁠L1 expression.","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)"}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 5;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/efficacy/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-first-line-monotherapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/efficacy/nsclc-perioperative-regimen/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/efficacy/nsclc-adjuvant-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-squamous-first-line-combination-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' }

            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;407' }, 
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;407' }, 
    { label: 'Appropriate Patients' }
]

const interimAnalysisComparisonTableFootnotes = [
    {
        label: 'a.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on stratified log-rank test.'
    },
]

const featuredStatFootnotes = [
    {
        label: 'b.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test.'
    }
]

const kmCurveFootnotes = [
    {
        label: 'c.',
        text: 'ITT population.'
    },
]

const interimAnalysisPFSComparisonTableFootnotes = [
    {
        label: 'd.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on stratified log-rank test.'
    }
]

const ORREndpointDisplayFootnotes = [
    {
        label: 'e.',
        text: 'Based on stratified Miettinen-Nurminen test.'
    }
]

const interimAnalysisDORComparisonTableFootnotes = [
    {
        label: 'f.',
        text: 'ORR primary analysis and DOR analysis were conducted with the first 204 patients enrolled.'
    }
]

const NCCNcalloutData = {
    title: 'Category 1 and PREFERRED recommendation regardless of PD&#8288;-&#8288;L1 expression<sup>4,g-i</sup>',
    bodyCopy: 'Pembrolizumab (KEYTRUDA), in combination with carboplatin and paclitaxel or paclitaxel protein&#8288;-&#8288;bound, is a recommended (category 1 and preferred) first-line treatment for patients with metastatic squamous non&#8288;&#8211;&#8288;small cell lung cancer (NSCLC), regardless of PD&#8288;-&#8288;L1 expression.',
    showBadge: false,
    alt: ""
}

const NCCNFootnotes = [
    {
        label: "g.",
        text: "See NCCN Guidelines® for detailed recommendations, including combination regimens, in patients with no targetable genomic tumor aberrations."
    },
    {
        label: "h.",
        text: "See NCCN Guidelines® for recommendations specific to genetic alterations, including <i>EGFR</i>, <i>ALK</i>, <i>ROS1</i>, <i>BRAF</i>, <i>MET</i>, <i>KRAS</i>, <i>NTRK1/2/3</i>, <i>RET</i>, and <i>ERBB2 (HER2)</i>."
    },
    {
        label: "i.",
        text: "Pembrolizumab/carboplatin/(paclitaxel or albumin-bound paclitaxel) is recommended (category 1 and preferred) as first-line therapy for certain patients with metastatic squamous non&#8288;–&#8288;small cell lung cancer (NSCLC)."
    }
]

const NCCNdefinitions = `<p>According to NCCN, preferred intervention = Intervention that is based on superior efficacy, safety, and evidence, and, when appropriate, affordability; Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>4</sup></p>
                        <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
                        <p>Albumin-bound paclitaxel = paclitaxel protein-bound; BRAF = B-Raf proto-oncogene, serine/threonine kinase; ERBB2 = Erb-B2 receptor tyrosine kinase 2; HER2 = human epidermal growth factor receptor 2; KRAS = Kirsten rat sarcoma viral oncogene homolog; MET = mesenchymal-epithelial transition; NTRK1/2/3 = neurotrophic tyrosine receptor kinase; RET = rearranged during transfection; ROS1 = ROS proto-oncogene 1, receptor tyrosine kinase.</p>`
const NCCNcalloutReferences = [
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.11.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 15, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const pageReferences = [
    {
        label: "1.",
        text: "Paz-Ares L, Luft A, Vicente D, et al; for the KEYNOTE⁠&#8288;-&#8288;⁠407 investigators. Pembrolizumab plus chemotherapy for squamous non–small-cell lung cancer. <i>N Engl J Med.</i> 2018;379(21):2040–2051."
    },
    {
        label: "2.",
        text: "Paz-Ares L, Vicente D, Tafreshi A, et al. A randomized, placebo-controlled trial of pembrolizumab plus chemotherapy in patients with metastatic squamous NSCLC: protocol-specified final analysis of KEYNOTE⁠&#8288;-&#8288;⁠407. <i>J Thorac Oncol.</i> 2020;15(10):1657–1669."
    },
    {
        label: "3.",
        text: "Novello S, Kowalski DM, Luft A, et al. Pembrolizumab plus chemotherapy in squamous non-small-cell lung cancer: 5-year update of the phase III KEYNOTE-407 study. <i>J Clin Oncol.</i> 2023;41(11):1999-2006. doi: 10.1200/JCO.22.01990"
    },
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.11.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 15, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const patientProfileDave = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-dave@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Dave",
    diagnosis: "Squamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '<1%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Liver',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '69',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
        {
            key: 'Smoking Status',
            value: 'Current smoker',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileNoriko = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-noriko@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Noriko",
    diagnosis: "Squamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: 'Unknown',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Liver',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '58',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking Status',
            value: '10-pack-year history; quit 30 years ago',
        },
        {
            key: 'Family History',
            value: 'Information unknown',
        },
    ],
}

const patientProfileGloria = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/gloria.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Gloria",
    diagnosis: "Squamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '20%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Brain (stable)',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '72',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
        {
            key: 'Smoking Status',
            value: 'Former smoker; 35 pack-year history',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileDefinitions = "mNSCLC = metastatic NSCLC; ECOG = Eastern Cooperative Oncology Group."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Durable Overall Survival With KEYTRUDA + Carbo + Paclitaxel or Paclitaxel Protein&#8288;-&#8288;Bound vs Carbo + Paclitaxel or Paclitaxel Protein&#8288;-&#8288;Bound</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Interim Analysis (Median Follow-up Time: 7.8 Months)<sup>1</sup>">
                                <SecondaryCallout 
                                    title="Superior overall survival with KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound vs carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound" 
                                    alt=""    
                                />
                                <ComparisonTable title="Overall Survival (OS)"
                                    footnotes={interimAnalysisComparisonTableFootnotes}
                                    definitions="carbo = carboplatin; HR = hazard ratio; CI = confidence interval; NE = not estimable."
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=278)',
                                            },
                                            {
                                                legendTitle: 'Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=281)',
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.64 (95% CI, 0.49–0.85; <i>P</i>=0.0017)<sup>a</sup>',
                                                colspan: 2,
                                                centerText: true
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '85',
                                                dataSubPoint: '(31%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '120',
                                                dataSubPoint: '(43%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '15.9 months',
                                                dataSubPoint: '(95% CI, 13.2–NE)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '11.3 months',
                                                dataSubPoint: '(95% CI, 9.5–14.8)'
                                            }
                                        ],
                                    ]
                                    } />

                                    <FeaturedStat 
                                        statistic="36%"
                                        title="Reduction in the risk of death with KEYTRUDA + carbo + paclitaxel or paclitaxel protein⁠-⁠bound vs carbo + paclitaxel or paclitaxel protein⁠-⁠bound<div style='margin-top: 10px;'><b>HR=0.64 (95% CI, 0.49–0.85; <i>P</i>=0.0017)<sup>b</sup></b></div>"                                        
                                        footnotes={featuredStatFootnotes}
                                    />

                            </PageSubSection>

                            <PageSubSection title="Protocol-Specified Final OS Analysis (Median Follow-up: 14.3 Months)<sup>2</sup>">
                                <ComparisonTable title="Overall Survival (OS)"
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=278)',
                                            },
                                            {
                                                legendTitle: 'Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=281)',
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.71 (95% CI, 0.58–0.88)',
                                                colspan: 2,
                                                centerText: true
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '17.1 months',
                                                dataSubPoint: '(95% CI, 14.4–19.9)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '11.6 months',
                                                dataSubPoint: '(95% CI, 10.1–13.7)'
                                            }
                                        ]
                                    ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title="Exploratory Analysis (Median Follow-up Time: 56.9 Months)<sup>3,c</sup>">
                                <KMCurve
                                    title="Overall Survival (OS), Regardless of PD-L1 Expression"
                                    hr="HR=0.71; 95% CI, 0.59–0.85"
                                    limitation="<p><b>LIMITATION:</b> This post-hoc analysis (median follow-up: 56.9 months) in KEYNOTE&#8288;-&#8288;407 was exploratory in nature and occurred after the protocol-specified final analysis. No formal statistical testing was planned for this updated analysis and, therefore, no statistical conclusions can be drawn. Trial participants in either study arm could receive subsequent anti-cancer therapy.</p>
                                    <p><b>CROSSOVER RATE:</b> 50.9%; 117 patients in the carbo + paclitaxel or paclitaxel protein-bound group crossed over to KEYTRUDA as monotherapy on-study and an additional 26 received subsequent anti&#8288;–&#8288;PD&#8288;-&#8288;(L)1 therapy outside the study.</p>
                                    "
                                    footnotes={kmCurveFootnotes}
                                    definitions="ITT = intent to treat; PD-L1 = programmed death ligand 1."
                                    image={() => <StaticImage src="../../assets/NSCLC-1L-Combo-SQ-KM-407_v5.png"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Curve Showing Overall Survival (OS) in Patients Regardless of PD-L1 Expression in KEYNOTE-407" />}
                                />

                                <ComparisonTable title="Overall Survival (OS)"
                                    data={[
                                        [
                                            {
                                                legendTitle: `KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=278)`,
                                            },
                                            {
                                                legendTitle: `Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=281)`,
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.71; 95% CI, 0.59–0.85',
                                                colspan: 2,
                                                centerText: true
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '225',
                                                dataSubPoint: '(80.9%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '248',
                                                dataSubPoint: '(88.3%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '17.2 months',
                                                dataSubPoint: '(95% CI, 14.4–19.7)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '11.6 months',
                                                dataSubPoint: '(95% CI, 10.1–13.7)'
                                            }
                                        ]
                                    ]
                                    } />                                
                            </PageSubSection>

                            <PageSubSection title="Interim Analysis: PFS and ORR">
                                <SecondaryCallout 
                                    title="KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound demonstrated superior PFS and ORR vs carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound" 
                                    alt=""    
                                />
                                <ComparisonTable title="Progression-Free Survival (PFS) (Median Follow-up Time: 7.8 Months)"
                                    footnotes={interimAnalysisPFSComparisonTableFootnotes}
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `<p>44% reduction in the risk of disease progression or death with KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound vs carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (HR=0.56; 95% CI, 0.45–0.70; <i>P</i><0.0001).<sup>d</sup></p>`
                                        },
                                    ]}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=278)',
                                            },
                                            {
                                                legendTitle: 'Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=281)',
                                            }
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.56 (95% CI, 0.45-0.70; <i>P</i><0.0001)<sup>d</sup>',
                                                colspan: 2,
                                                centerText: true
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '152',
                                                dataSubPoint: '(55%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '197',
                                                dataSubPoint: '(70%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '6.4 months',
                                                dataSubPoint: '(95% CI, 6.2–8.3)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.8 months',
                                                dataSubPoint: '(95% CI, 4.2–5.7)'
                                            }
                                        ]
                                    ]
                                    } />

                                <EndpointDisplay 
                                    title="Objective Response Rate (ORR): Over Half of Patients Responded With KEYTRUDA + Carbo + Paclitaxel or Paclitaxel Protein-Bound" 
                                    limitation="The ORR primary analysis and DOR analysis were conducted with the first 204 patients enrolled, in 101 patients treated with KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound and 103 patients treated with carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound."
                                    footnotes={ORREndpointDisplayFootnotes}
                                    middleP="=0.0008<sup>e</sup>"
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound',
                                                rate: '58%',
                                                ci: '95% CI, 48–68',
                                            },

                                            {
                                                dataType: 'ORR',
                                                label: 'Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound',
                                                rate: '35%',
                                                ci: '95% CI, 26–45',
                                            }
                                        ]
                                    }
                                />

                                <ComparisonTable title="Duration of Response (DOR)<sup>f</sup>"
                                    footnotes={interimAnalysisDORComparisonTableFootnotes}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA + carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=101)',
                                            },
                                            {
                                                legendTitle: 'Carbo + paclitaxel or paclitaxel protein&#8288;-&#8288;bound (n=103)',
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '7.2 months',
                                                dataSubPoint: '(range: 2.4–12.4+ months)'
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '4.9 months',
                                                dataSubPoint: '(range: 2.0–12.4+ months)'
                                            }
                                        ]
                                    ]
                                    } />
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="cloud">
                            <PageSubSection title={anchorLinkModelData[2].label}>
                                <NSCLC_1L_COMBO_SQ />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNFootnotes}
                                definitions={NCCNdefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;407">
                                <PatientProfile {...patientProfileGloria} definitions={patientProfileDefinitions} />
                                <PatientProfile {...patientProfileDave} definitions={patientProfileDefinitions} />
                                <PatientProfile {...patientProfileNoriko} definitions={patientProfileDefinitions} />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
